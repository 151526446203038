{
  "data": {
    "gameClient": {
      "observerServerIp": "192.64.174.163",
      "observerServerPort": 80,
      "running": true,
      "serverIp": "192.64.173.108",
      "serverPort": 5248,
      "visible": false
    },
    "gameData": {
      "gameId": 4399502141,
      "gameName": "",
      "isCustomGame": false,
      "password": "",
      "playerChampionSelections": [
        {
          "championId": 202,
          "selectedSkinIndex": 23,
          "spell1Id": 4,
          "spell2Id": 7,
          "summonerInternalName": "jsonfileformat"
        },
        {
          "championId": 21,
          "selectedSkinIndex": 0,
          "spell1Id": 4,
          "spell2Id": 6,
          "summonerInternalName": "bilibilikiwi"
        },
        {
          "championId": 221,
          "selectedSkinIndex": 0,
          "spell1Id": 21,
          "spell2Id": 4,
          "summonerInternalName": "creborn"
        },
        {
          "championId": 63,
          "selectedSkinIndex": 30,
          "spell1Id": 32,
          "spell2Id": 4,
          "summonerInternalName": "theonecrashfu"
        },
        {
          "championId": 84,
          "selectedSkinIndex": 32,
          "spell1Id": 32,
          "spell2Id": 4,
          "summonerInternalName": "theonehighfu"
        }
      ],
      "queue": {
        "allowablePremadeSizes": [
          1,
          2,
          3,
          4,
          5
        ],
        "areFreeChampionsAllowed": true,
        "assetMutator": "",
        "category": "PvP",
        "championsRequiredToPlay": 16,
        "description": "ARAM",
        "detailedDescription": "",
        "gameMode": "ARAM",
        "gameTypeConfig": {
          "advancedLearningQuests": false,
          "allowTrades": true,
          "banMode": "SkipBanStrategy",
          "banTimerDuration": 0,
          "battleBoost": true,
          "crossTeamChampionPool": false,
          "deathMatch": false,
          "doNotRemove": false,
          "duplicatePick": false,
          "exclusivePick": true,
          "id": 21,
          "learningQuests": false,
          "mainPickTimerDuration": 0,
          "maxAllowableBans": 0,
          "name": "GAME_CFG_TEAM_BUILDER_RANDOM",
          "onboardCoopBeginner": false,
          "pickMode": "AllRandomPickStrategy",
          "postPickTimerDuration": 33,
          "reroll": false,
          "teamChampionPool": false
        },
        "id": 450,
        "isRanked": false,
        "isTeamBuilderManaged": true,
        "isTeamOnly": false,
        "lastToggledOffTime": 1539839685494,
        "lastToggledOnTime": 1658274579389,
        "mapId": 12,
        "maxLevel": 0,
        "maxSummonerLevelForFirstWinOfTheDay": 0,
        "maximumParticipantListSize": 5,
        "minLevel": 6,
        "minimumParticipantListSize": 1,
        "name": "ARAM",
        "numPlayersPerTeam": 5,
        "queueAvailability": "Available",
        "queueRewards": {
          "isChampionPointsEnabled": true,
          "isIpEnabled": true,
          "isXpEnabled": true,
          "partySizeIpRewards": []
        },
        "removalFromGameAllowed": false,
        "removalFromGameDelayMinutes": 0,
        "shortName": "ARAM",
        "showPositionSelector": false,
        "spectatorEnabled": true,
        "type": "ARAM_UNRANKED_5x5"
      },
      "spectatorsAllowed": false,
      "teamOne": [
        {
          "accountId": 202729814,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":2,\"p\":0.435},\"505007\":{\"l\":6,\"p\":0.239},\"505006\":{\"l\":7,\"p\":0.175},\"101000\":{\"l\":5,\"p\":0.025},\"505005\":{\"l\":6,\"p\":0.253},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":7,\"p\":0.014},\"2022002\":{\"l\":4,\"p\":0.061},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":6,\"p\":0.191},\"501001\":{\"l\":3,\"p\":0.202},\"600006\":{\"l\":5,\"p\":0.269},\"2022001\":{\"l\":5,\"p\":0.037},\"101104\":{\"l\":4,\"p\":0.056},\"101203\":{\"l\":5,\"p\":0.028},\"101303\":{\"l\":4,\"p\":0.061},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[],\"title\":{\"challengeId\":101206,\"level\":2,\"itemId\":10120601}}",
            "championOwned": "false",
            "perks": "{\"perkIds\":[8021,8009,9103,8017,8473,8242,5005,5008,5001],\"perkStyle\":8000,\"perkSubStyle\":8400}",
            "ranked": "{\"isRankedQueue\":false,\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[1459,1459,1459,1459,1459,1459,1459,0,0]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":86}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 0,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 590,
          "puuid": "7da50831-1eeb-55ec-8c15-9253ab180c9c",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 39794525,
          "summonerInternalName": "creborn",
          "summonerName": "CReborn",
          "teamOwner": false,
          "teamParticipantId": 1,
          "teamRating": 0,
          "timeAddedToQueue": 1660000841322,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000841322,
          "voterRating": 0
        },
        {
          "accountId": 246479857,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "Companions": "{\"Companions\":[{\"itemId\":22012,\"contentId\":\"bb7b7d72-2d92-4dd8-a462-a352dae9e1e3\",\"inventoryType\":\"COMPANION\"}]}",
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"0ffdb549-8c1b-4add-a3aa-5b4dd368fc58\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.276},\"505007\":{\"l\":6,\"p\":0.239},\"505006\":{\"l\":7,\"p\":0.175},\"101000\":{\"l\":6,\"p\":0.001},\"505005\":{\"l\":7,\"p\":0.109},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":9,\"p\":0.001},\"2022002\":{\"l\":8,\"p\":0.0},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":6,\"p\":0.191},\"501001\":{\"l\":9,\"p\":0.0},\"600006\":{\"l\":9,\"p\":0.0},\"2022001\":{\"l\":5,\"p\":0.037},\"101104\":{\"l\":6,\"p\":0.001},\"101203\":{\"l\":9,\"p\":0.0},\"101303\":{\"l\":6,\"p\":0.001},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[101101,2022002,101203],\"title\":{\"challengeId\":101203,\"level\":4,\"itemId\":10120303}}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8128,8126,8138,8134,8009,8014,5007,5002,5003],\"perkStyle\":8100,\"perkSubStyle\":8000}",
            "ranked": "{\"isRankedQueue\":false,\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[{\"contentId\":\"68d99841-92c1-477f-91fe-e9d238f55d0b\",\"payload\":{\"currentValue\":9888,\"isShowcased\":false,\"maxDelta\":62,\"disabled\":false}},{\"contentId\":\"8edf3255-da84-45ad-946b-8290019ffa11\",\"payload\":{\"currentValue\":0,\"isShowcased\":false,\"maxDelta\":0,\"disabled\":false}},{\"contentId\":\"9b581863-6ed6-49cf-9306-7e0f50d8a294\",\"payload\":{\"currentValue\":828,\"isShowcased\":false,\"maxDelta\":7,\"disabled\":false}},{\"contentId\":\"34278259-e57c-47d4-8d93-549dc16e8260\",\"payload\":{\"currentValue\":1359,\"isShowcased\":true,\"maxDelta\":31,\"disabled\":false}},{\"contentId\":\"6c15fcca-7365-4658-a1bc-7675fd6cd2c2\",\"payload\":{\"currentValue\":809,\"isShowcased\":true,\"maxDelta\":24,\"disabled\":false}},{\"contentId\":\"cd7c5954-31d5-4ce0-bc35-518eb890093b\",\"payload\":{\"currentValue\":1585,\"isShowcased\":true,\"maxDelta\":51,\"disabled\":false}}]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3622,3494,1452,1505,3172,3203,3139,3349,3168]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":true}",
            "wardSkin": "{\"wardSkinId\":84}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 30,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 4763,
          "puuid": "70762a69-3d17-5712-bc4d-c68c0cb347cf",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 93899849,
          "summonerInternalName": "theonecrashfu",
          "summonerName": "theOneCrashFu",
          "teamOwner": false,
          "teamParticipantId": 2,
          "teamRating": 0,
          "timeAddedToQueue": 1660000832987,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000832987,
          "voterRating": 0
        },
        {
          "accountId": 206702034,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "Companions": "{\"Companions\":[{\"itemId\":22031,\"contentId\":\"bdcfaa0c-6318-44a8-a403-0b5539187524\",\"inventoryType\":\"COMPANION\"}]}",
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.276},\"505007\":{\"l\":7,\"p\":0.16},\"505006\":{\"l\":7,\"p\":0.175},\"101000\":{\"l\":6,\"p\":0.001},\"505005\":{\"l\":7,\"p\":0.109},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":9,\"p\":0.001},\"2022002\":{\"l\":7,\"p\":0.0},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":7,\"p\":0.131},\"501001\":{\"l\":5,\"p\":0.112},\"600006\":{\"l\":7,\"p\":0.021},\"2022001\":{\"l\":8,\"p\":0.001},\"101104\":{\"l\":6,\"p\":0.001},\"101203\":{\"l\":7,\"p\":0.0},\"101303\":{\"l\":5,\"p\":0.01},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[501001,600006,101101],\"title\":{\"challengeId\":101100,\"level\":6,\"itemId\":10110005}}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8229,8226,8210,8236,8138,8105,5008,5008,5001],\"perkStyle\":8200,\"perkSubStyle\":8100}",
            "ranked": "{\"isRankedQueue\":false,\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[{\"contentId\":\"ab2b7b02-ea86-4324-9827-1b847616af8b\",\"payload\":{\"currentValue\":424,\"isShowcased\":true,\"maxDelta\":35,\"disabled\":false}},{\"contentId\":\"ab5a43c8-93ee-4a4e-8e03-fe47d9f625ff\",\"payload\":{\"currentValue\":0,\"isShowcased\":true,\"maxDelta\":0,\"disabled\":false}},{\"contentId\":\"dd30a0a0-16ac-47e1-bcdb-e4b9e4e337bc\",\"payload\":{\"currentValue\":35,\"isShowcased\":true,\"maxDelta\":6,\"disabled\":false}}]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3939,3177,3478,1481,3940,3745,3409,3741,1514]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 32,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 5418,
          "puuid": "fc303626-1e1b-5914-9508-df3501e4b1ce",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 43719349,
          "summonerInternalName": "theonehighfu",
          "summonerName": "TheOneHighFu",
          "teamOwner": false,
          "teamParticipantId": 2,
          "teamRating": 0,
          "timeAddedToQueue": 1660000832987,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000832987,
          "voterRating": 0
        },
        {
          "accountId": 237092665,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":2,\"p\":0.435},\"505007\":{\"l\":4,\"p\":0.463},\"505006\":{\"l\":6,\"p\":0.276},\"101000\":{\"l\":5,\"p\":0.025},\"505005\":{\"l\":4,\"p\":0.404},\"505004\":{\"l\":5,\"p\":0.33},\"101101\":{\"l\":8,\"p\":0.0},\"2022002\":{\"l\":6,\"p\":0.01},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":3,\"p\":0.388},\"501001\":{\"l\":0,\"p\":1.0},\"600006\":{\"l\":5,\"p\":0.269},\"2022001\":{\"l\":7,\"p\":0.005},\"101104\":{\"l\":5,\"p\":0.009},\"101203\":{\"l\":3,\"p\":0.091},\"101303\":{\"l\":5,\"p\":0.01},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[505006,505004,600006]}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8128,8139,8138,8135,9111,8014,5005,5008,5003],\"perkStyle\":8100,\"perkSubStyle\":8000}",
            "ranked": "{\"isRankedQueue\":false,\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3164,1513,0,1464,3409,0,0,0,0]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":86}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 0,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 1437,
          "puuid": "9908f109-b492-5367-baa8-5654ab74588d",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 83860325,
          "summonerInternalName": "bilibilikiwi",
          "summonerName": "BiliBiliKiwi",
          "teamOwner": false,
          "teamParticipantId": 3,
          "teamRating": 0,
          "timeAddedToQueue": 1660000841683,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000841683,
          "voterRating": 0
        },
        {
          "accountId": 2484241709934240,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "Companions": "{\"Companions\":[{\"itemId\":42016,\"contentId\":\"0ed19cda-6009-4688-8d3a-5e98760ce26e\",\"inventoryType\":\"COMPANION\"}]}",
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.276},\"505007\":{\"l\":5,\"p\":0.323},\"505006\":{\"l\":6,\"p\":0.276},\"101000\":{\"l\":4,\"p\":0.076},\"505005\":{\"l\":6,\"p\":0.253},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":7,\"p\":0.027},\"2022002\":{\"l\":6,\"p\":0.01},\"303402\":{\"l\":7,\"p\":0.008},\"505001\":{\"l\":5,\"p\":0.249},\"501001\":{\"l\":4,\"p\":0.138},\"600006\":{\"l\":5,\"p\":0.269},\"2022001\":{\"l\":4,\"p\":0.08},\"101104\":{\"l\":3,\"p\":0.105},\"101203\":{\"l\":2,\"p\":0.148},\"101303\":{\"l\":5,\"p\":0.01},\"2022018\":{\"l\":7,\"p\":0.088}},\"selected\":[303402,101101,2022018]}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8128,8139,8138,8106,8014,8009,5008,5008,5001],\"perkStyle\":8100,\"perkSubStyle\":8000}",
            "ranked": "{\"isRankedQueue\":false,\"tier\":\"SILVER\",\"rank\":\"III\",\"previousSeasonAchievedTier\":\"SILVER\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[]}",
            "summonerBanner": "{\"flag\":{\"level\":\"3\",\"theme\":\"MSI2022\"},\"frame\":{\"level\":\"3\"}}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3176,3356,3829,3140,3159,3518,3728,3153,1505]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":202}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 23,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 5424,
          "puuid": "85415c3a-cade-5581-9ad5-375140ec94ba",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 121092233,
          "summonerInternalName": "jsonfileformat",
          "summonerName": "json file format",
          "teamOwner": false,
          "teamParticipantId": 4,
          "teamRating": 0,
          "timeAddedToQueue": 1660000840862,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000840862,
          "voterRating": 0
        }
      ],
      "teamTwo": [
        {
          "accountId": 2317961156520384,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "Companions": "{\"Companions\":[{\"itemId\":34001,\"contentId\":\"ecbbc3d8-c9ea-4894-8d1a-bccdfb327105\",\"inventoryType\":\"COMPANION\"}]}",
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"rank\"},\"banner\":{\"displayType\":\"0ffdb549-8c1b-4add-a3aa-5b4dd368fc58\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.276},\"505007\":{\"l\":6,\"p\":0.239},\"505006\":{\"l\":7,\"p\":0.175},\"101000\":{\"l\":6,\"p\":0.001},\"505005\":{\"l\":7,\"p\":0.109},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":9,\"p\":0.001},\"2022002\":{\"l\":8,\"p\":0.001},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":7,\"p\":0.131},\"501001\":{\"l\":5,\"p\":0.112},\"600006\":{\"l\":5,\"p\":0.269},\"2022001\":{\"l\":5,\"p\":0.037},\"101104\":{\"l\":6,\"p\":0.001},\"101203\":{\"l\":6,\"p\":0.009},\"101303\":{\"l\":6,\"p\":0.001},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[101303,101000,101104],\"title\":{\"challengeId\":101100,\"level\":6,\"itemId\":10110005}}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8008,8009,9103,8014,8139,8135,5005,5008,5002],\"perkStyle\":8000,\"perkSubStyle\":8100}",
            "ranked": "{\"isRankedQueue\":false,\"tier\":\"BRONZE\",\"rank\":\"I\",\"previousSeasonAchievedTier\":\"SILVER\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[]}",
            "summonerBanner": "{\"flag\":{\"level\":\"1\",\"theme\":\"MSI2021\"},\"frame\":{\"level\":\"1\"}}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3153,3411,3276,3273,0,3581,1459,1459,3166]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":191}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 0,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 5292,
          "puuid": "69c659c0-ac62-5fea-a83b-ce42c19211f6",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 111461726,
          "summonerInternalName": "dosempk",
          "summonerName": "DoseMPK",
          "teamOwner": false,
          "teamParticipantId": 5,
          "teamRating": 0,
          "timeAddedToQueue": 1660000843406,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000843406,
          "voterRating": 0
        },
        {
          "accountId": 33154656,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":2,\"p\":0.435},\"505007\":{\"l\":7,\"p\":0.16},\"505006\":{\"l\":7,\"p\":0.175},\"101000\":{\"l\":4,\"p\":0.076},\"505005\":{\"l\":6,\"p\":0.253},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":7,\"p\":0.023},\"2022002\":{\"l\":2,\"p\":0.187},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":7,\"p\":0.131},\"501001\":{\"l\":4,\"p\":0.138},\"600006\":{\"l\":5,\"p\":0.269},\"2022001\":{\"l\":4,\"p\":0.08},\"101104\":{\"l\":4,\"p\":0.056},\"101203\":{\"l\":5,\"p\":0.028},\"101303\":{\"l\":1,\"p\":0.187},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[505007,101101,505001],\"title\":{\"challengeId\":101104,\"level\":4,\"itemId\":10110403}}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8229,8226,8210,8236,8138,8105,5008,5008,5002],\"perkStyle\":8200,\"perkSubStyle\":8100}",
            "ranked": "{\"isRankedQueue\":false,\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3270,3140,3898,3935,1476,3422,3150,1476,3661]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":220}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 0,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 5147,
          "puuid": "23598501-c19e-5cb8-a3dc-19de0225702e",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 20262169,
          "summonerInternalName": "arphe",
          "summonerName": "Arphe",
          "teamOwner": false,
          "teamParticipantId": 6,
          "teamRating": 0,
          "timeAddedToQueue": 1660000832918,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000832918,
          "voterRating": 0
        },
        {
          "accountId": 37313616,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "Companions": "{\"Companions\":[{\"itemId\":18007,\"contentId\":\"4bb4256d-8ad2-447c-9996-baf8eb395571\",\"inventoryType\":\"COMPANION\"}]}",
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":2,\"p\":0.435},\"505007\":{\"l\":7,\"p\":0.16},\"505006\":{\"l\":7,\"p\":0.175},\"101000\":{\"l\":5,\"p\":0.025},\"505005\":{\"l\":6,\"p\":0.253},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":8,\"p\":0.0},\"2022002\":{\"l\":5,\"p\":0.024},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":6,\"p\":0.191},\"501001\":{\"l\":0,\"p\":1.0},\"600006\":{\"l\":5,\"p\":0.269},\"2022001\":{\"l\":8,\"p\":0.001},\"101104\":{\"l\":5,\"p\":0.009},\"101203\":{\"l\":6,\"p\":0.009},\"101303\":{\"l\":4,\"p\":0.061},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[101101,2022001,101203],\"title\":{\"challengeId\":101203,\"level\":4,\"itemId\":10120303}}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8128,8126,8138,8106,8009,8014,5008,5008,5002],\"perkStyle\":8100,\"perkSubStyle\":8000}",
            "ranked": "{\"isRankedQueue\":false,\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3896,3811,3623,3897,0,3896,3896,3896,3896]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":0}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 1,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 539,
          "puuid": "a2b6be2e-9b5d-506b-8b91-19e629802e16",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 28009503,
          "summonerInternalName": "kazonova",
          "summonerName": "Kazonova",
          "teamOwner": false,
          "teamParticipantId": 6,
          "teamRating": 0,
          "timeAddedToQueue": 1660000832918,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000832918,
          "voterRating": 0
        },
        {
          "accountId": 33263100,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "Companions": "{\"Companions\":[{\"itemId\":4001,\"contentId\":\"51308483-ce6c-49e7-9727-aa300827016f\",\"inventoryType\":\"COMPANION\"}]}",
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.276},\"505007\":{\"l\":7,\"p\":0.16},\"505006\":{\"l\":7,\"p\":0.175},\"101000\":{\"l\":5,\"p\":0.025},\"505005\":{\"l\":7,\"p\":0.109},\"505004\":{\"l\":6,\"p\":0.21},\"101101\":{\"l\":8,\"p\":0.005},\"2022002\":{\"l\":4,\"p\":0.061},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":7,\"p\":0.131},\"501001\":{\"l\":3,\"p\":0.202},\"600006\":{\"l\":6,\"p\":0.118},\"2022001\":{\"l\":7,\"p\":0.002},\"101104\":{\"l\":4,\"p\":0.056},\"101203\":{\"l\":6,\"p\":0.009},\"101303\":{\"l\":4,\"p\":0.061},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[505005,101101,505006],\"title\":{\"challengeId\":101104,\"level\":4,\"itemId\":10110403}}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8439,8463,8444,8242,9111,9105,5008,5002,5002],\"perkStyle\":8400,\"perkSubStyle\":8000}",
            "ranked": "{\"isRankedQueue\":false,\"previousSeasonAchievedTier\":\"GOLD\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3153,3247,3120,3581,3896,0,0,0,0]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":62}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 0,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 5147,
          "puuid": "b93ebc26-36bd-55df-823a-be53dbddd1d0",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 20333209,
          "summonerInternalName": "yannoiecool",
          "summonerName": "yannoIecool",
          "teamOwner": false,
          "teamParticipantId": 6,
          "teamRating": 0,
          "timeAddedToQueue": 1660000832918,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000832918,
          "voterRating": 0
        },
        {
          "accountId": 313753,
          "adjustmentFlags": 0,
          "botDifficulty": "NONE",
          "clientInSynch": true,
          "gameCustomization": {
            "Companions": "{\"Companions\":[{\"itemId\":22008,\"contentId\":\"be77de5c-6f19-4e32-a1e7-2f14bc03e701\",\"inventoryType\":\"COMPANION\"}]}",
            "GoldenSpatulaClub": "{\"Rewards\":[]}",
            "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
            "challenges": "{\"challenges\":{\"0\":{\"l\":2,\"p\":0.435},\"505007\":{\"l\":5,\"p\":0.323},\"505006\":{\"l\":6,\"p\":0.276},\"101000\":{\"l\":4,\"p\":0.076},\"505005\":{\"l\":6,\"p\":0.253},\"505004\":{\"l\":5,\"p\":0.33},\"101101\":{\"l\":7,\"p\":0.026},\"2022002\":{\"l\":4,\"p\":0.061},\"303402\":{\"l\":0,\"p\":1.0},\"505001\":{\"l\":4,\"p\":0.312},\"501001\":{\"l\":5,\"p\":0.112},\"600006\":{\"l\":5,\"p\":0.269},\"2022001\":{\"l\":4,\"p\":0.08},\"101104\":{\"l\":4,\"p\":0.056},\"101203\":{\"l\":4,\"p\":0.056},\"101303\":{\"l\":4,\"p\":0.061},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[505005,101101,505006],\"title\":{\"challengeId\":101104,\"level\":4,\"itemId\":10110403}}",
            "championOwned": "true",
            "perks": "{\"perkIds\":[8229,8226,8210,8236,8105,8138,5007,5008,5001],\"perkStyle\":8200,\"perkSubStyle\":8100}",
            "ranked": "{\"isRankedQueue\":false,\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":false,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
            "statstones": "{\"statstones\":[{\"contentId\":\"84b76f7c-8704-4b82-984a-5b3fa05e82a2\",\"payload\":{\"currentValue\":34,\"isShowcased\":true,\"maxDelta\":7,\"disabled\":false}},{\"contentId\":\"fd046258-e6fd-4a51-9193-5b19260ca9c5\",\"payload\":{\"currentValue\":2,\"isShowcased\":true,\"maxDelta\":2,\"disabled\":false}},{\"contentId\":\"ff8c56e8-538b-42d5-8d42-ce194ca363a8\",\"payload\":{\"currentValue\":328,\"isShowcased\":true,\"maxDelta\":46,\"disabled\":false}}]}",
            "summonerEmotes": "{\"summonerEmoteIds\":[3116,3000,3678,1501,1459,3000,3116,3116,3116]}",
            "summonerTrophy": "null",
            "vintageSkin": "{\"isVintageSkin\":false}",
            "wardSkin": "{\"wardSkinId\":49}"
          },
          "index": 0,
          "lastSelectedSkinIndex": 4,
          "locale": null,
          "minor": false,
          "originalAccountNumber": 0,
          "originalPlatformId": null,
          "partnerId": "",
          "pickMode": 1,
          "pickTurn": 0,
          "profileIconId": 5147,
          "puuid": "daa71a0f-2b3c-50ca-8aa5-089a402cbf46",
          "queueRating": 0,
          "rankedTeamGuest": false,
          "selectedPosition": "NONE",
          "selectedRole": "NONE.NONE.FILL.UNSELECTED",
          "summonerId": 286918,
          "summonerInternalName": "yannolecool",
          "summonerName": "yannolecool",
          "teamOwner": false,
          "teamParticipantId": 6,
          "teamRating": 0,
          "timeAddedToQueue": 1660000832918,
          "timeChampionSelectStart": 1660000844689,
          "timeGameCreated": 1660000921195,
          "timeMatchmakingStart": 1660000832918,
          "voterRating": 0
        }
      ]
    },
    "gameDodge": {
      "dodgeIds": [],
      "phase": "None",
      "state": "Invalid"
    },
    "map": {
      "assets": {
        "champ-select-background-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/sound/music-cs-allrandom-howlingabyss.ogg",
        "champ-select-banphase-background-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/sound/music-cs-allrandom-banphase-howlingabyss.ogg",
        "champ-select-flyout-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/champ-select-flyout-background.png",
        "game-select-icon-active": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/game-select-icon-active.png",
        "game-select-icon-active-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/video/game-select-icon-active.webm",
        "game-select-icon-default": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/game-select-icon-default.png",
        "game-select-icon-disabled": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/game-select-icon-disabled.png",
        "game-select-icon-hover": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/game-select-icon-hover.png",
        "game-select-icon-intro-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/video/game-select-icon-intro.webm",
        "gameflow-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/gameflow-background.jpg",
        "gameflow-background-dark": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/gameflow-background-dark.jpg",
        "gameselect-button-hover-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Shared/sound/sfx-gameselect-button-hover.ogg",
        "icon-defeat": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-defeat.png",
        "icon-defeat-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-defeat-v2.png",
        "icon-defeat-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/video/icon-defeat.webm",
        "icon-empty": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-empty.png",
        "icon-hover": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-hover.png",
        "icon-leaver": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-leaver.png",
        "icon-leaver-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-leaver-v2.png",
        "icon-loss-forgiven-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-loss-forgiven-v2.png",
        "icon-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-v2.png",
        "icon-victory": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/icon-victory.png",
        "icon-victory-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/video/icon-victory.webm",
        "music-inqueue-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/sound/music-inqueue-loop-howlingabyss.ogg",
        "parties-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/parties-background.jpg",
        "postgame-ambience-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/sound/sfx-ambience-loop-howlingabyss.ogg",
        "ready-check-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/ready-check-background.png",
        "ready-check-background-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/sound/sfx-readycheck-ha-portal.ogg",
        "sfx-ambience-pregame-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/sound/sfx-ambience-loop-howlingabyss.ogg",
        "social-icon-leaver": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/social-icon-leaver.png",
        "social-icon-victory": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/ARAM/img/social-icon-victory.png"
      },
      "categorizedContentBundles": {},
      "description": "The Howling Abyss is a bottomless crevasse located in the coldest, cruelest, part of the Freljord. Legends say that, long ago, a great battle took place here on the narrow bridge spanning this chasm. No one remembers who fought here, or why, but it is said that if you listen carefully to the wind you can still hear the cries of the vanquished tossed howling into the Abyss.",
      "gameMode": "ARAM",
      "gameModeName": "ARAM",
      "gameModeShortName": "ARAM",
      "gameMutator": "",
      "id": 12,
      "isRGM": false,
      "mapStringId": "HA",
      "name": "Howling Abyss",
      "perPositionDisallowedSummonerSpells": {},
      "perPositionRequiredSummonerSpells": {},
      "platformId": "",
      "platformName": "",
      "properties": {
        "suppressRunesMasteriesPerks": false
      }
    },
    "phase": "InProgress"
  },
  "eventType": "Update",
  "uri": "/lol-gameflow/v1/session"
}